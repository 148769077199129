import React from "react";

import GitHubLogo from "../assets/images/link-images/github-logo.svg";
import LinkedInLogo from "../assets/images/link-images/linkedIn-logo.svg";
import InstagramLogo from "../assets/images/link-images/instagram-logo.svg";
import TwitterLogo from "../assets/images/link-images/twitter-logo.svg";

const logos = [
  {
    img: GitHubLogo,
    href: "https://github.com/martincoutts?tab=repositories",
    linkName: "Github",
  },
  {
    img: LinkedInLogo,
    href: "https://www.linkedin.com/in/martin-coutts-89095617b/",
    linkName: "LinkedIn",
  },
  {
    img: InstagramLogo,
    href: "https://www.instagram.com/martincoutts.dev/",
    linkName: "Instagram",
  },
  {
    img: TwitterLogo,
    href: "https://twitter.com/martincouttsdev",
    linkName: "Twitter",
  },
];

const Links = () => (
  <div className="LinkSection">
    {/* Maps over each object in logos array and creates a link tile */}
    {logos.map((logo, index) => (
      <div className="link-tile" key={index + 1}>
        <a href={logo.href} target="_blank" rel="noopener noreferrer">
          <img
            id={`link-tile-${index}`}
            src={logo.img}
            alt={`${logo.linkName} logo`}
          />
        </a>
      </div>
    ))}
  </div>
);

export default Links;
