import React, { Component } from "react";
import { Button } from "react-bootstrap";
import ReactGA from "react-ga";

export default class ContactSection extends Component {
  render() {
    const handleGAEvent = () => {
      ReactGA.event({
        category: "Form Submit",
        action: "User submitted contact form by clicking send button"
      });
    };
    return (
      <div className="contact-section">
        <h1>Contact Me</h1>
        <p>
          If you have any questions or require further information please use
          the form below to contact me and I will get back to you as soon as
          possible.
        </p>

        <div id="contact-form">
          <form
            name="contact"
            method="post"
            encType="application/x-www-form-urlencoded"
          >
            <div id="contact-user-details">
              <input type="hidden" name="form-name" value="contact" />
              <input type="text" name="name" placeholder="Your Name" required />

              <input
                type="email"
                name="email"
                placeholder="youremail@example.com"
                required
              />
            </div>

            <div id="contact-user-message">
              <textarea
                name="message"
                placeholder="Your Message Here:"
                rows="7"
                required
              ></textarea>
            </div>

            <Button
              type="submit"
              id="contact-submit-button"
              onClick={handleGAEvent}
            >
              Send
            </Button>
          </form>
        </div>
      </div>
    );
  }
}
