import React from "react";
import ProjectDiv from "./ProjectDiv";
import ProjectSearchBar from "./ProjectSearchBar";

const ProjectPageWrapper = (props) => {
  const {
    projectData,
    filteredProjects,
    setFilterSelect,
    filterSelect,
  } = props;
  return (
    <div className="project-page-wrapper">
      <ProjectSearchBar
        setFilterSelect={setFilterSelect}
        filterSelect={filterSelect}
      />
      <ProjectDiv
        projectData={projectData}
        filteredProjects={filteredProjects}
      />
    </div>
  );
};

export default ProjectPageWrapper;
