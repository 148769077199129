import React, { Component } from "react";
import ReactGA from "react-ga";

import { Button } from "antd";

import { SlideDown } from "react-slidedown";

import { Image } from "react-bootstrap";
import "../../node_modules/font-awesome/css/font-awesome.css";
import { techStackHelper } from "../utilities/techStackHelper";

class ProjectCard extends Component {
  state = {
    hoveredOn: false,
    showDescription: false,
  };

  render() {
    const handleGAEvent = () => {
      ReactGA.event({
        category: "Project Click",
        action: `User clicked on ${this.props.projectData.name} link`,
      });
    };

    const showDescription = () => {
      this.setState({
        showDescription: !this.state.showDescription,
      });
    };

    const descriptionChildren = (
      <>
        <div>
          {/* Each paragraph is an item in the array, when it is loaded into the component the array is mapped over and each item is placed into a paragraph tag */}
          {this.props.projectData.description.map((paragraph, index) => (
            <p key={index + 1}>{paragraph}</p>
          ))}
        </div>
      </>
    );

    const descriptionIcon = !this.state.showDescription
      ? "fa fa-angle-down"
      : "fa fa-angle-up";

    return (
      <div className="project-card" id={this.props.id}>
        <div className="coloured-title-stripe">
          <a
            target="_blank"
            href={this.props.projectData.pageLink}
            rel="noopener noreferrer"
          >
            <h3
              className={
                this.state.hoveredOn ? "title-effect" : "no-title-effect"
              }
            >
              {this.props.projectData.name}
            </h3>
          </a>
        </div>
        <div className="main-content">
          <hr />
          <a
            target="_blank"
            href={this.props.projectData.pageLink}
            rel="noopener noreferrer"
            onClick={handleGAEvent}
          >
            <Image
              className="project-image"
              onMouseEnter={() => this.setState({ hoveredOn: true })}
              onMouseLeave={() => this.setState({ hoveredOn: false })}
              src={require(`../assets/images/project-images/${this.props.projectData.thumbnailSrc}`)}
              alt="Project Screenshot"
              fluid
            />
          </a>
          <hr />

          <SlideDown className={"my-dropdown-slidedown"}>
            {this.state.showDescription ? descriptionChildren : null}
          </SlideDown>
          <div className="main-content__tech-stack">
            {techStackHelper(
              this.props.projectData.techStack,
              this.props.projectData.gitHubLink,
              "projectCard"
            )}
          </div>
          <Button
            className="hiddenSingleColumn showMoreButton"
            onClick={showDescription}
          >
            <span>See Description</span>
            <i className={descriptionIcon} />
          </Button>
        </div>
      </div>
    );
  }
}

export default ProjectCard;
