import React from "react";
import { Link } from "react-router-dom";

const Footer = () => (
  <footer className="footer">
    <Link to="/" id="navbar-brand">
      <h5>Martin Coutts | Developer</h5>
    </Link>
  </footer>
);

export default Footer;
