import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

// Component imports
import Header from "./Header";

import LandingPage from "./LandingPage";

import ProjectDiv from "./ProjectDiv";
import ProjectPageWrapper from "./ProjectPageWrapper";
import ContactSection from "./ContactSection";
import Links from "./Links";
import Footer from "./Footer";

// Function Imports
import ScrollToTop from "../utilities/ScrollToTop";
import { filterProjects } from "../utilities/filterProjects";

const history = createBrowserHistory();

// Google Analytics ////////////////////////////////
const trackingId = "UA-108461105-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

////////////////////////////////////////////////////

const projectData = require("../assets/data/projectData.json");

const App = () => {
  const [filteredProjects, setFilteredProjects] = useState(null);
  const [filterSelect, setFilterSelect] = useState(null);

  useEffect(() => {
    // Required to allow google analytics to work correctly
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    const filteredProjects = filterSelect
      ? filterProjects(filterSelect, projectData)
      : null;
    setFilteredProjects(filteredProjects);
  }, [filterSelect]);

  return (
    <Router history={history}>
      <ScrollToTop>
        <div className="App">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Martin Coutts | Developer</title>
            <link rel="canonical" href="http://www.martincoutts.com" />
            <meta
              name="description"
              content="Portfolio and contact information for Martin Coutts, web developer, Glasgow, Scotland"
            />
            <meta
              name="keywords"
              content="web, developer, react, javascript, css, html, sass, seo, git, responsive, design, glasgow, scotland"
            />
          </Helmet>
          <Header />
          <Switch>
            <Route exact path="/">
              <LandingPage projectData={projectData} />
            </Route>

            <Route path="/projects">
              <ProjectPageWrapper
                projectData={projectData}
                filteredProjects={filteredProjects}
                setFilterSelect={setFilterSelect}
                filterSelect={filterSelect}
              />
            </Route>
            <Route path="/contact" component={ContactSection} />
            <Route path="/links" component={Links} />
          </Switch>

          <Footer />
        </div>
      </ScrollToTop>
    </Router>
  );
};
export default App;
