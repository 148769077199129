import React from "react";

const WelcomeText = () => {
  return (
    <div>
      <div className="header-text">
        <p>Hi, how are you? My name is Martin and I like to create things!</p>
        <p>
          I am a JavaScript developer and my focus is building good looking,
          functional and responsive applications to solve interesting problems.
        </p>
        <p>
          I am always looking for a new challenge or project to be a part of so
          if you are interested in working with me please get in touch.
        </p>
      </div>
    </div>
  );
};

export default WelcomeText;
