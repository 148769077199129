import React from "react";
import ProjectCard from "./ProjectCard";

const ProjectDiv = (props) => {
  const { projectData, filteredProjects } = props;
  const mapValue = filteredProjects ? filteredProjects : projectData;

  return (
    <div className="project-div">
      {mapValue.map((project, index) => (
        <ProjectCard
          projectData={project}
          key={index + 1}
          id={`projectCard${index + 1}`}
          className="test"
        />
      ))}
    </div>
  );
};

export default ProjectDiv;
