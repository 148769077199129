import React from "react";
import { Tooltip } from "antd";

import SearchBarIcon from "../components/SearchBarIcon";

import html5 from "../assets/images/tech-images/html5.svg";
import javascript from "../assets/images/tech-images/javascript.svg";
import react from "../assets/images/tech-images/react.svg";
import sass from "../assets/images/tech-images/sass.svg";
import vue from "../assets/images/tech-images/vue.svg";
import vuex from "../assets/images/tech-images/vuex.svg";
import redux from "../assets/images/tech-images/redux.svg";
import jest from "../assets/images/tech-images/jest.svg";
import angular from "../assets/images/tech-images/angular.svg";

// Used in all items
import github from "../assets/images/tech-images/github.svg";

export const imagePaths = [
  { name: "html5", path: html5 },
  { name: "javascript", path: javascript },
  { name: "react", path: react },
  { name: "vue", path: vue },
  { name: "angular", path: angular },
  { name: "redux", path: redux },
  { name: "vuex", path: vuex },
  { name: "sass", path: sass },
  { name: "jest", path: jest },
];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const techStackHelper = (
  stack,
  githubLink,
  type,
  hookCall,
  selectedFilter
) => {
  let cells = [];
  if (type === "projectCard") {
    stack.map((item) => {
      imagePaths.map((object) => {
        const tooltTipText = <span>{capitalizeFirstLetter(object.name)}</span>;
        if (item === object.name) {
          cells.push(
            <Tooltip placement="top" title={tooltTipText} key={item}>
              <img src={object.path} alt={`${item} logo`} key={item} />
            </Tooltip>
          );
        }
      });
    });

    cells.push(
      <Tooltip placement="top" title={<span>Github</span>} key={github}>
        <a href={githubLink} target="_blank" rel="noopener noreferrer">
          <img src={github} alt={"github link"} key={github} />
        </a>
      </Tooltip>
    );
  } else if (type === "projectSearchBar") {
    imagePaths.map((imagePath, index) => {
      const isActive = selectedFilter === imagePath.name;

      // const activeClass = isActive
      //   ? "project-search-bar__image project-search-bar__image--active"
      //   : "project-search-bar__image";

      let activeClass;
      if (isActive) {
        activeClass =
          "project-search-bar__image project-search-bar__image--active";
      } else if (!isActive && selectedFilter !== null) {
        activeClass =
          "project-search-bar__image project-search-bar__image--inactive";
      } else {
        activeClass =
          "project-search-bar__image project-search-bar__image--no-selected";
      }

      if (imagePath.name !== "html5" && imagePath.name !== "javascript") {
        cells.push(
          <SearchBarIcon
            index={index}
            imagePath={imagePath}
            activeClass={activeClass}
            isActive={isActive}
            hookCall={hookCall}
          />
        );
      } else return null;
    });
  }

  return <>{cells}</>;
};
