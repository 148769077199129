import React from "react";
import { techStackHelper } from "../utilities/techStackHelper";

export default function ProjectSearchBar(props) {
  const { setFilterSelect, filterSelect } = props;
  return (
    <div
      data-testid="ProjectSearchBar-container"
      className="project-search-bar"
    >
      {techStackHelper(
        null,
        null,
        "projectSearchBar",
        setFilterSelect,
        filterSelect
      )}
    </div>
  );
}
