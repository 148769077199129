import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

import Logo from "../assets/images/martincouttsLogo.svg";

const navLinks = [
  {
    href: ``,
    text: "Home",
  },
  {
    href: `projects`,
    text: "Projects",
  },
  {
    href: `links`,
    text: "Links",
  },
  {
    href: `contact`,
    text: "Contact",
  },
];

const navLinkStyle = {
  color: `black`,
};

const Header = () => (
  <header className="header">
    <Navbar expand="lg">
      <Link to="/" id="navbar-brand">
        <img src={Logo} alt="site logo"></img>
      </Link>

      <Navbar.Toggle id="nav-toggle-button" aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav style={navLinkStyle} className="mr-auto nav-links">
          {navLinks.map((link, index) => (
            <NavLink
              to={`/${link.href}`}
              exact
              activeClassName="active"
              activeStyle={{
                // Gradient-red variable
                color: "rgba(211, 26, 26, 0.8)",

                fontWeight: "bold",
                textDecoration: "underline"

              }}
              className="navLink"
              style={navLinkStyle}
              key={index + 1}
            >
              {link.text}
            </NavLink>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>
);

export default Header;
