import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import ReactGA from "react-ga";
import WelcomeText from "./WelcomeText";
import ProjectDiv from "./ProjectDiv";
import ContactSection from "./ContactSection";

const handleGAEvent = () => {
  ReactGA.event({
    category: "Button Click",
    action: "User clicked More Projects button"
  });
};

const LandingPage = props => {
  return (
    <div className="landing-page">
      <WelcomeText />
      <React.Fragment>
        <ProjectDiv projectData={props.projectData} />
      </React.Fragment>
      <div className="more-projects">
        <Link to="/projects">
          <Button
            className="more-projects__button"
            onClick={handleGAEvent}
            size="large"
            shape="round"
            type="primary"
          >
            More Projects
          </Button>
        </Link>
      </div>
      <div className="landing-page__contact-section">
        <ContactSection />
      </div>
    </div>
  );
};

export default LandingPage;
