import React from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const SearchBarIcon = (props) => {
  const { index, imagePath, activeClass, isActive, hookCall } = props;

  return (
    <div
      className="project-search-bar__cell"
      key={`${imagePath.name}-cell`}
      data-testid={`SearchBarIcon-wrapper-${index}`}
    >
      <img
        data-testid={`searchbar-icon`}
        className={activeClass}
        src={imagePath.path}
        alt={`${imagePath.name} logo`}
        key={imagePath.name}
        onClick={() => hookCall(imagePath.name)}
      />
      {isActive && (
        <Button
          data-testid={`searchbar-close-button`}
          onClick={() => hookCall(null)}
          className="project-search-bar__button"
          type="danger"
          shape="circle"
          icon={<CloseOutlined />}
          size={"small"}
        />
      )}
    </div>
  );
};

export default SearchBarIcon;
